import React from 'react'

const corporateForm = () => (
    <div className="container pt-120 pb-100">
        <div className='row'>
            <div className='col-12'>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSduOt-ETeKfXmbgMs71iTVjXSBmGl5DAGKxtt3yKEkHck8NUg/viewform?embedded=true" width="100%" height="1000px" frameBorder="0" marginHeight="0" marginWidth="0" title="Contact us">Loading...</iframe>
            </div>
        </div>
    </div>
)

export default corporateForm